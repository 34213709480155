import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../layouts/index";
import SEO from "../components/seo";
import * as stringUtils from "../utils/string";

const ProjectTemplate = props => {
  const { data } = props;
  const project = data.wordpressWpProjects;

  return (
    <Layout cssClass='single-project blog-style'>
      <SEO title={stringUtils.sanitize(project.title)} />

      <div className='main-content'>
        <h1 dangerouslySetInnerHTML={{ __html: project.title }} />
        <div dangerouslySetInnerHTML={{ __html: project.content }} />
      </div>
    </Layout>
  );
};

export default ProjectTemplate;

export const pageQuery = graphql`
  query currentWpProjectsQuery($id: String!) {
    wordpressWpProjects(id: { eq: $id }) {
      title
      slug
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      link
      filters
      content
      featured_media_url
      acf_meta {
        description
        informations {
          contributors {
            link
            name
          }
          date
          scope
        }
        screenshot {
          image {
            alt
            caption
            url
            filename
            description
          }
        }
        source {
          url
        }
        stack {
          link
          name
          version
        }
      }
    }
  }
`;

ProjectTemplate.propTypes = {
  data: PropTypes.object.isRequired
};
